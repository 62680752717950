import {ref, watch, nextTick} from 'vue';
// import {useAuth, useChat, chatList, messagesCollection} from '@/firebase';
import mxnProducts from '@/mixins/products';
import mxnCustomer from '@/mixins/customer';
import mxnSwal from '@/mixins/toast';
import mxnAuth from '@/mixins/auth';
import { mapState, mapGetters } from 'vuex'
import _ from 'lodash';
import moment from 'moment';

export default {
    name: 'ChatMessages',
    mixins: [mxnAuth, mxnProducts, mxnSwal],
    computed: {
        ...mapState(['msgCollection', 'conversations', 'pendingConversations', 'rawMessageList', 'agentList']),
        ...mapGetters(['activeMessages', 'assignedAgent']),
        currentMessages() {
            const el = this.$refs.messageBottom;
            this.lastMessage = _.last(this.activeMessages) || {};
            if (el) {
                // Use el.scrollIntoView() to instantly scroll to the element when DOM is already updated
                // console.info(this.$refs.listScroll.clientHeight,this.$refs.listScroll.offsetHeight)
                // let scrolledHeight = this.$refs.listScroll.scrollHeight
                // console.info(scrolledHeight)
                nextTick().then(() => {
                    el.scrollIntoView({behavior: 'smooth', block: 'nearest'});
                    // this.$refs.listScroll.scrollTo({
                    //     behavior: 'smooth',
                    //     top: scrolledHeight
                    //   })
                });
            } 
            return this.activeMessages;
        },
        // conversationsList(oldList, newList) {
        //     console.error(this.conversations[0])
        //     if (this.conversations.length) {
        //         if ((!isNaN(this.requestProductId) && this.requestProductId != null) || this.isGeneralChat) {
        //             console.error(this.requestProductId)
        //             this.selectConversation(this.conversations[0]);
        //             this.requestProductId = null;
        //             this.isGeneralChat = null;
        //         }
        //         // let lastConvo = _.last(this.conversations[]) || {};
        //         if ((this.activeUserDetails.senderId === parseInt(this.loggedId) || this.recentlySent) 
        //             && this.activeUserDetails.id 
        //             && this.activeUserDetails.id !== this.conversations[0].id
        //             && this.activeUserDetails.productId === this.conversations[0].productId) {
        //             // console.warn(this.activeUserDetails.senderId, parseInt(this.loggedId))
        //             // console.error(this.activeUserDetails.id, this.loggedId)
        //             this.activeUserDetails = this.conversations[0];
        //             this.recentlySent = false;
        //         }
        //     }
            
        //     return this.conversations
        // }
    },
    watch: {
        conversations: {
            handler(newConvo, oldConvo) {
                if ((this.conversations[0] && !isNaN(this.requestProductId) && this.requestProductId != null) || this.isGeneralChat) {
                    console.error(this.requestProductId)
                    this.selectConversation(this.conversations[0]);
                    this.requestProductId = null;
                    this.isGeneralChat = null;
                }
                // let lastConvo = _.last(this.conversations[]) || {};
                if (this.conversations[0]
                    && (this.activeUserDetails.senderId === parseInt(this.loggedId) || this.recentlySent) 
                    && this.activeUserDetails.id 
                    && this.activeUserDetails.id !== this.conversations[0].id
                    && this.activeUserDetails.productId === this.conversations[0].productId) {
                    // console.warn(this.activeUserDetails.senderId, parseInt(this.loggedId))
                    // console.error(this.activeUserDetails.id, this.loggedId)
                    this.activeUserDetails = this.conversations[0];
                    this.recentlySent = false;
                }

                let notif = document.getElementById('hpc-chat-support');
                if (notif && this.conversations.length) {
                    const t = this.conversations.map(i => i.unread || 0).reduce((a,b) => a+b);
                    if (t) {
                        notif.setAttribute('data-total-notif', t)
                    } else {
                        notif.removeAttribute('data-total-notif')
                    }
                }
                console.info(this.conversations);
            }
        }
    },
    data() {
        return {
            isMiniChat: false,
            username: "",
            loggedId: null,
            activeUserDetails: {},
            message: '',
            // messages: [],
            userDetails: {},
            lastMessage: {},
            userType: 'agent',
            convo: [],
            requestProductId: null,
            recentlySent: false,
            isLoading: false,
            onProgress: false,
            activeMessageTop: 0,
            showMiniChat: false,
            orderId: null,
            isOnline: false
        }
    },
    methods: {
        authenticate() {

            const loggedUserDetatails = {
                id: this.loggedId,
                type: this.userType,
            }

            if (!sessionStorage.getItem("HPC_TOKEN")) {
                mxnAuth.chatToken().then(
                    r => {
                        if (r.data?.token) {
                            sessionStorage.setItem("HPC_TOKEN", r.data.token);
                            // store session on vuex
                            this.$store.dispatch('setSession', r.data.token);
                            
                            this.$store.dispatch('getCustomers', loggedUserDetatails);

                            // this.getProductDetails();
                            // console.warn(r.data);
                            if (loggedUserDetatails.type === 'customer') {
                                this.getCustomerLoggedDetails()
                            }
                        }
                    }, e => {
                        console.error(e)
                })
            } else {
                this.$store.dispatch('getCustomers', loggedUserDetatails);

                if (loggedUserDetatails.type === 'customer') {
                    this.getCustomerLoggedDetails()
                }
            }
            // TODO: get token
            // if (!sessionStorage.getItem("HPC_TOKEN")) {
            //     const param = {
            //         "username": "hpcadmin",
            //         "password": "admin123"
            //     }
            //     mxnAuth.authJWT(param).then(r => {
            //         let data = r.data;
            //         sessionStorage.setItem("HPC_TOKEN", data.token);
            //         // store session on vuex
            //         this.$store.dispatch('setSession', data.token);
                    
            //         this.$store.dispatch('getCustomers', loggedUserDetatails);

            //         // this.getProductDetails();
            //         // console.warn(r.data);
            //         if (loggedUserDetatails.type === 'customer') {
            //             this.getCustomerLoggedDetails()
            //         }
            //     }, e => {
            //         console.error(e)
            //     })
            // } else {
            //     this.$store.dispatch('getCustomers', loggedUserDetatails);

            //     if (loggedUserDetatails.type === 'customer') {
            //         this.getCustomerLoggedDetails()
            //     }
            //     // this.getProductDetails();
            // }
        },
        getCustomerLoggedDetails() {
            mxnCustomer.getCustomer(this.loggedId).then(
                r => {
                    // console.warn(r.data)
                    let user = r.data;
                    this.userDetails = {
                        id: user.id,
                        date_created: user.date_created,
                        email: user.email,
                        first_name: user.first_name,
                        last_name: user.last_name,
                        role: user.role
                    }

                    // this.getProductDetails('request');return
                    if (!isNaN(this.requestProductId) && this.requestProductId != null) {
                        // console.info(this.requestProductId, requestProductName)
                        // this.send(this.requestProductId, 'open');
                        this.getProductDetails('request');
                        // replace URL
                        let newUrl = window.location.origin + window.location.pathname;
                        window.history.replaceState(null, null, newUrl);
                    }  else if (this.cartQuantty != null && this.cartContents != null) {
                        
                        // console.info(this.requestProductId, requestProductName)
                        
                        this.getProductDetails('cart_contents');
                        // replace URL
                        let newUrl = window.location.origin + window.location.pathname;
                        window.history.replaceState(null, null, newUrl);
                    }
                },
                e => {
                    console.error(e)
                }
            )
        },
        selectConversation(user) {
            this.isLoading = true
            // get active user
            this.activeUserDetails = user
            // build param of conversation
            let params = {
                type: user.details.role,
                user_id: user.details.id,
                product_id: user.productId,
                logged_type: this.userType,
                logged_user_id: this.loggedId,
                chat_type: user.chatType,
                order_id: user.orderId,
                callback: () => {this.isLoading = false}
            }
            // reset top
            this.activeMessageTop = 0;
            // call list of conversation
            this.$store.dispatch('getActiveConversation', params);
        },
        send(val, remark, products = []) {
            let user = this.activeUserDetails;
            let params = {};
            // loading
            if (!this.onProgress) {
                this.isLoading = true
            }
            

            if ((typeof val === 'object' && val.id) && this.userType === 'agent') {
                let sender = val.newAgentId || this.loggedId;
                // let agent = this.agentList.filter(i => i.id === sender)[0].name;
                let msg = "Hey there! I'm " + this.userFullname + ", Welcome to our store. What can we help you with today?";
                // reply by agent
                params = {
                    type: val.details.role,
                    user_id: val.details.id,
                    message: msg,
                    product_id: val.productId,
                    product_details: val.productDetails,
                    customer_details: val.details,
                    logged_type: this.userType,
                    logged_user_id: sender,
                    chat_type: val.chatType,
                    order_id: val.orderId
                }
            } else if (user.details) {
                // reply message
                params = {
                    type: user.details.role,
                    user_id: user.details.id,
                    message: this.message.replaceAll("\\n", "\n"),
                    product_id: user.productId,
                    product_details: user.productDetails,
                    customer_details: user.details,
                    logged_type: this.userType,
                    logged_user_id: this.loggedId,
                    chat_type: user.chatType,
                    order_id: user.orderId
                }

                // for reopen product_list save again the items // customer do not allowed to reopen cart list requested
                /* if (user.chatType === 'product_list' && remark === 'open') {
                    params.cart_contents = this.activeMessages[0].cartContents;
                    params.cart_contents_quantity = this.activeMessages[0].cartContentsQuantity;
                    remark = 'cart_contents';
                    params.remark = remark;
                } */

            } else if (!isNaN(val)) {
                // create new request 1 product only
                params = {
                    type: this.userType,
                    user_id: this.loggedId,
                    message: '',
                    product_id: parseInt(val),
                    product_details: products,
                    logged_type: this.userType,
                    logged_user_id: this.loggedId,
                    chat_type: 'request'
                }
            } else if (remark === 'general_chat') {
                // create new general chat
                const date = new Date();
                date.setHours(0,0,0,0);

                const time = date.getTime();
                params = {
                    type: this.userType,
                    user_id: this.loggedId,
                    message: '',
                    product_id: time,
                    logged_type: this.userType,
                    logged_user_id: this.loggedId,
                    chat_type: remark
                }

                this.isGeneralChat = true;
            } else if (remark === 'cart_contents') {
                // create new product list RFQ
                const date = new Date();
                date.setHours(0,0,0,0);

                const time = date.getTime();
                params = {
                    type: this.userType,
                    user_id: this.loggedId,
                    message: '',
                    product_id: time,
                    product_details: products,
                    // cart_contents: this.cartContents,
                    // cart_contents_quantity: this.cartQuantty,
                    order_id: this.orderId,
                    logged_type: this.userType,
                    logged_user_id: this.loggedId,
                    remark: remark,
                    chat_type: 'product_list'
                }

                this.isGeneralChat = true;
            }
            
            // TODO: conversation message close
            if (remark === 'close' || remark === 'open') {
                params.remark = remark;
            }

            // for multiple product request
            if (user.remark === "cart_contents") {
                params.remark = user.remark;
            }

            // send a link to pay
            if (this.userType === 'agent' && remark === 'payment') {
                params.remark = remark;
            }

            // if sender is customer and has assigned agent
            if (this.userType === 'customer' && this.assignedAgent.senderId) {
                params.assigned_agent = this.assignedAgent.remark === 'close' ? 0 : this.assignedAgent.senderId;
            } else if (this.userType === 'customer'){
                // set no assign agent
                params.assigned_agent = 0;

                // get customer details
                params.customer_details = this.userDetails
            }

            // get agent name
            if (this.userType === 'agent') {
                params.agent_name = this.userFullname
            }

            // set user who triggered the send message
            this.recentlySent = true;
            // console.info(params, this.assignedAgent)
            // return

            // setTimeout(() => {this.isLoading = false},1000)
            // console.info("user", user);
            // console.info("params", params);
            // return;
            this.$store.dispatch('sendMessage', params).then(r => {
                /* if (r.id && !isNaN(val)) {
                    console.info(r.id, val);
                    // select lastes conversaion
                    this.selectConversation(this.conversations[0]);
                } */

                // close message if the agent requested to close the thread
                if ((this.userType === 'agent' && r.id && params.remark === 'close') || (typeof val === 'object' && val.newAgentId)) {
                    this.closeMessage();
                }
                
                this.isLoading = false
                this.onProgress = false
            });

            const elem = document.getElementById('hpc-chat-field')
            if (elem) {
                elem.value = '';
            }
            
            this.message = '';
        },
        convoHeader() {
            // console.info(this.activeUserDetails)
            // if from general chat
            if (this.activeUserDetails.chatType === 'general_chat') {
                return moment(this.activeUserDetails.productId).format("MMMM DD, YYYY");
            } else if (this.activeUserDetails.chatType === 'product_list') {
                return "Order RFQ #" + this.activeUserDetails.orderId + " (" + moment(this.activeUserDetails.productId).format("MMMM DD, YYYY") + ")";
            }
            return this.activeUserDetails.details ? this.userType === 'customer' ? this.activeUserDetails.productDetails[0].name : this.activeUserDetails.details.first_name + " " + this.activeUserDetails.details.last_name + " (" + this.activeUserDetails.productDetails[0].name + ")" : "--"
        },
        getProductDetails(action) {
            let params = {include: []};
            let act = action;
            if (action === 'request') {
                params.include = [this.requestProductId]
            } else if (action === 'cart_contents') {

                params.include = this.cartContents.split(",");
            }

            mxnProducts.getProductDetail(params).then(r => {
                let data = r.data;

                // console.info(params, action)
                // console.info(data)
                if (data.length && act === 'request') {
                    let productList = data.map(i => {
                        return {
                            id: i.id,
                            name: i.name,
                            image: i.images[0] ? i.images[0].src : '',
                            permalink: i.permalink,
                            description: i.description
                        }
                    })
                    this.send(this.requestProductId, 'open', productList);
                } else if (data.length && act === 'cart_contents') { 
                    let quantities = {};
                    let qty = this.cartQuantty.split(',');
                    for (let i = 0; i < params.include.length; i++) {
						const element = parseInt(params.include[i]);
						quantities[element] = qty[i]
					}
                    let productList = data.map(i => {
                        return {
                            id: i.id,
                            name: i.name,
                            image: i.images[0] ? i.images[0].src : '',
                            permalink: i.permalink,
                            quantity: quantities[i.id]
                        }
                    })
                    this.send(undefined,'cart_contents', productList);
                }

            }, e => {
                console.info(e)
            })
            
        },
        getUserConvoHeader(convo) {
            if (convo.senderId === parseInt(this.loggedId)) {
                return 'You';
            } else if (this.userType === 'customer'){
                return convo.agentName;
            } else if (this.userType === 'agent'){
                return this.activeUserDetails.details ? this.activeUserDetails.details.first_name + " " + this.activeUserDetails.details.last_name : '';
            }
        },
        closeMessage() {
            this.$store.dispatch('unsetMessage');
            this.activeUserDetails = {};
        },
        markAsClose() {
            
            mxnSwal.confirmation({
                // icon: 'warning',
                title: "Are you sure you want to close this thread message?",
                msg: 'This action cannot be undone',
                confirmed: () => {
                    // console.info(r)
                    this.onProgress = true;
                    this.send(undefined,'close');
                }
            } )
        },
        reAssignConfirmation() {
            let options = {};
            let details = _.cloneDeep(this.activeUserDetails);
            this.agentList.map(item => {
                if (item.id != this.loggedId) {
                    options[item.id] = item.name
                }
                
            });

            // console.info(options)
            mxnSwal.selectConfirmation({
                // icon: 'warning',
                title: "Re-assign to the other agent",
                options: options,
                placeholder: "Please select agent",
                selectedValue: (r) => {
                    // console.info(r)
                    this.onProgress = true;
                    details.newAgentId = parseInt(r)
                    this.send(details);
                }
            } )
        },
        assignConfirmation(convo) {
            // console.info(convo);
            let details = convo;
            let fullname = convo.details.first_name + " " + convo.details.last_name;
            let msg = convo.productDetails.name ? "Inquiring for product " + convo.productDetails.name : 'Requesting for live chat';
            mxnSwal.confirmation({
                // icon: 'warning',
                title: "Are you sure you want to assign customer " + fullname + " to you?",
                msg: msg,
                confirmed: () => {
                    this.onProgress = true;
                    // console.info(r)
                    this.send(details);
                }
            } )
        },
        customerInfo() {
            let details = _.cloneDeep(this.activeUserDetails);
            // console.info(details)
            let info = '<div class="mui-row hpc-customer-info"><div class="mui-col-md-12"><div class="mui-row hpc-customer-fields">' + 
                '<div class="mui-col-md-6">Full Name</div>' +
                '<div class="mui-col-md-6 hpc-customer-fullname">' + details.details.first_name + " " + details.details.last_name + '</div>' +
                '</div>' +
                '<div class="mui-row hpc-customer-fields">' + 
                '<div class="mui-col-md-6">Email</div>' +
                '<div class="mui-col-md-6">' + details.details.email + '</div>' +
                '</div>' +
                '<div class="mui-row hpc-customer-fields">' + 
                '<div class="mui-col-md-6">Mobile Number</div>' +
                '<div class="mui-col-md-6">' + (details.details.shipping?.phone || '--') + '</div>' +
                '</div>' +
                '<div class="mui-row hpc-customer-fields">' + 
                '<div class="mui-col-md-6">Address</div>' +
                '<div class="mui-col-md-6">' + (details.details.shipping?.address_1 || '--')  + '</div>' +
                '</div></div></div>'
            mxnSwal.infoDetails({
                // icon: 'warning',
                title: 'Customer Information',
                html: info
                
            } )
        },
        handleEnter(evt) {
            this.message = evt.target.value
            // console.info(evt);
            if (evt.keyCode == 13 && !evt.shiftKey) {
                // console.info(this.message);
                this.send(); //Submit your form here
                // clear
                evt.target.value = '';

                evt.preventDefault();
                return false;
            }

            // console.info(this.message)

        },
        getDate(d) {
            return moment(d).format('MMMM DD, YYYY hh:mmA')
        },
        minimizeMiniChat(e) {
            this.showMiniChat = true;
        },
        inputOrderNumber() {
            const _this = this;
            _this.send(undefined, 'payment')
            /* 
            mxnSwal.inputOrder({
                handler: (v) => {
                    // console.info(v);
                    _this.send(undefined, 'payment')
                }
            }) */
            // send(undefined, 'payment')
        }
    },
    mounted () {
        // upon create DOM init collection
        this.$store.dispatch('getCollection');

        // get logged user details
        let element = document.getElementById("hpc-chat-app");
        let bodyElement = document.getElementById("hpc-body-content");
        this.userType = element.getAttribute("data-chat-type");
        this.userFullname = element.getAttribute("data-username");
        this.loggedId = parseInt(element.getAttribute("data-uid"));
        // get order id
        if (bodyElement) {
            this.orderId = parseInt(bodyElement.getAttribute("data-orderid"));
        }

        this.isMiniChat = element.getAttribute("data-layout") === 'mini-chat';
        // console.log('layout:',element.getAttribute("data-layout"))
        // console.info(this.orderId)
        // prevent guest user to load chat message
        if (this.loggedId) {
            
            // create new request
            this.requestProductId = new URL(location.href).searchParams.get('request_qoute_id');
            this.cartContents = new URL(location.href).searchParams.get('cart-contents'); //?cart-contents=1,2,3,4,5
            this.cartQuantty = new URL(location.href).searchParams.get('cart-contents-quantity'); //?cart-contents=1,2,3,2,1
          
            // TODO: proceed to authenticate user upon visiting agent dashboard
            this.authenticate();
            // console.info("asd")

        }
    }
}
